import React from "react";
import "./App.css";
import { useState, useEffect } from "react";
import IMG_1720 from "./assets/IMG_1720.jpg";
import IMG_8465 from "./assets/IMG_8465.jpg";
import IMG_8533 from "./assets/IMG_8533.jpg";
import IMG_1707 from "./assets/IMG_1707.jpg";
import IMG_8464 from "./assets/IMG_8464.jpg";
import IMG_8471 from "./assets/IMG_8471.jpg";

const App = () => {
  const [showMessage, setShowMessage] = React.useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio("/romantic_music.mp3"));
  const images = [IMG_1720, IMG_8465, IMG_8533, IMG_1707, IMG_8464, IMG_8471];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFireworks, setShowFireworks] = useState(false);
  const [showHandwrittenMessage, setShowHandwrittenMessage] = useState(false);

  useEffect(() => {
    audio.loop = true;
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const toggleMusic = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const floatingHearts = Array(5)
    .fill()
    .map((_, i) => (
      <div
        key={i}
        className="floating-heart"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 2}s`,
        }}
      >
        ❤️
      </div>
    ));

  const triggerFireworks = () => {
    setShowFireworks(true);
    setTimeout(() => setShowFireworks(false), 5000);
  };

  const triggerHandwrittenMessage = () => {
    setShowHandwrittenMessage(true);
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-fixed flex flex-col items-center justify-center text-center p-6 relative overflow-hidden">
      <div className="animated-bg"></div>
      {floatingHearts}
      {showFireworks && <Fireworks />}
      <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-lg max-w-md relative z-10 transform hover:scale-105 transition-transform duration-300">
        <header className="mb-6">
          <div className="w-full h-64 mb-4 overflow-hidden rounded-lg shadow-md">
            <img
              src={images[currentImageIndex]}
              alt="Mohammed and Wijdan"
              className="w-full h-full object-cover transition-opacity duration-500 fade-in hover:opacity-90"
            />
          </div>
          <h1 className="text-4xl font-bold text-pink-500 animate-pulse">
            I'm Sorry, Wijdan
          </h1>
          <p className="text-lg mt-2 text-gray-700">
            I made a mistake, and I want to make things right.
          </p>
        </header>
        <main>
          <h2 className="text-2xl font-semibold text-gray-800">
            Why You Mean So Much to Me
          </h2>
          <p className="mt-4 text-gray-600">
            You are the most important person in my life. Your kindness, love,
            and support mean everything to me. I regret my actions and promise
            to be a better partner.
          </p>
          <div className="mt-6">
            <h3 className="text-xl font-semibold text-gray-800">My Promises</h3>
            <ul className="list-disc list-inside mt-2 text-gray-600">
              <li>I will always be honest with you</li>
              <li>I will support your dreams and aspirations</li>
              <li>I will work on improving our communication</li>
              <li>I will show you love and appreciation every day</li>
            </ul>
          </div>
          <button
            className="mt-6 bg-pink-500 text-white px-6 py-3 rounded-full hover:bg-pink-600 transition duration-300 transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 heartbeat"
            onClick={() => {
              setShowMessage(true);
              triggerFireworks();
              triggerHandwrittenMessage();
            }}
          >
            I Love You, Wijdan
          </button>
          {showMessage && (
            <div className="mt-4 text-pink-600 font-bold animate-bounce">
              Your love means the world to me. I'm truly sorry and I promise to
              do better.
            </div>
          )}
          {showHandwrittenMessage && <HandwrittenMessage />}
          <button
            className="mt-4 bg-gray-300 text-gray-700 px-4 py-2 rounded-full text-sm hover:bg-gray-400 transition duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            onClick={toggleMusic}
          >
            {isPlaying ? "Pause Music" : "Play Music"}
          </button>
        </main>
        <footer className="mt-6">
          <p className="text-gray-600">
            I promise to work on myself and make you proud.
          </p>
          <p className="text-lg font-bold text-pink-500 mt-2 animate-pulse">
            Love, Mohammed 7bibek
          </p>
        </footer>
      </div>
    </div>
  );
};

export default App;

const Fireworks = () => {
  return (
    <div className="fireworks">
      {Array(20)
        .fill()
        .map((_, i) => (
          <div
            key={i}
            className="firework"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 2}s`,
            }}
          />
        ))}
    </div>
  );
};

const HandwrittenMessage = () => {
  return (
    <div className="mt-6 p-4 bg-yellow-100 rounded-lg shadow-md">
      <p className="handwritten-text text-gray-800 text-lg">
        Wijdan, my love,
        <br />
        Every day with you is a blessing.
        <br />
        I promise to cherish and love you
        <br />
        more and more with each passing moment.
        <br />
        You are my heart, my soul, my everything.
        <br />
        Forever yours,
        <br />
        Mohammed
      </p>
    </div>
  );
};
